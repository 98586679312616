import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { IPRViewModel, QueueFilterViewModel, ServerResponseWithBody } from 'src/app/_models/app.models';
import { IBudgetCode, ItemDetailsHeader } from 'src/app/_models/item-details.models';
import { IPRItemDetails } from 'src/app/_models/pr.models';
import { RestService } from 'src/app/_services/rest.service';
import { getUrlPathFragment } from 'src/app/_static/util';
import { PurchaseRequisitionLineItemViewComponent } from 'src/app/components/purchase-requisition/purchase-requisition-line-item-view/purchase-requisition-line-item-view.component';

@Component({
  selector: 'tg-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit {
  page: number = 1;
  pageSize: number = 5;
  totalRecords: number = 0;
  selectedReports: IPRViewModel[] = [];
  filterValues: string[] = [];
  filterParams!: QueueFilterViewModel;
  @Input() itemDetails!: IPRItemDetails[];
  @Input() itemTableHeaderConfig!: ItemDetailsHeader;
  @Input() itemTotal!: number;
  itemHeader!: any
  itemData!: BehaviorSubject<IPRItemDetails[]>;
  eventsArray: { id: number, event: boolean, rowIndex: number }[] = [];
  nullEventsArray: { id: string, event: boolean, rowIndex: number }[] = [];
  @Input() PRDetailId!: number
  @Output() deleteItems = new EventEmitter<{ deleteItemsList: { id: number, event: boolean }[], PRDetailId: number }>();
  @Output() nullDeleteItems = new EventEmitter<{ deleteItemsList: { id: string, event: boolean }[], PRDetailId: number }>();
  @Output() totalPRAmount = new EventEmitter<number>();
  @Input() showTotalRow!: boolean
  @Output() convertPOItems = new EventEmitter<{ convertArray: { event: boolean, rowData: IPRItemDetails }[] }>();
  totalGstAmount!: number;
  total!: number;
  convertArray: { event: boolean, rowData: IPRItemDetails }[] = []
  lineItemViewRef!: BsModalRef;
  @Output() editedLineItemData = new EventEmitter<IPRItemDetails>();
  totalAmountWoGst!: number;
  @Input() budgetId!: number;
  budgetList!: IBudgetCode[];
  budgetDetailsList: any[] = [];
  @Output() budget = new EventEmitter<any>();
  @Output() rowIndex = new EventEmitter<any>();
  previousRowData!: IPRItemDetails
  @Output() rowData = new EventEmitter<IPRItemDetails>();
  @Input() disableBudget: boolean = false;
  @Input() isDisable: boolean = false;
  gstDetails!: IPRItemDetails;
  constructor(private modalService: BsModalService,
    private restService: RestService,) {
  }

  ngOnInit(): void {
    for (let i = 0; i < this.itemDetails.length; i++) {
      this.itemDetails[i].budgetDropdownFormControl = new FormControl(this.itemDetails[i].budgetId);
      this.itemDetails[i].budgetDropdownFormControl?.setValue(this.itemDetails[i].budgetId)
    }
    this.getBudgetCode();
    this.itemData = new BehaviorSubject<IPRItemDetails[]>(this.itemDetails);
    this.calculateData()
    this.getHeaderArray()
    this.totalAmountWoGst = this.calculateTotalWoGst()
    this.totalGstAmount = this.calculateTotalGstAmount();
    this.total = this.calculateTotal()
  }
  getBudgetCode() {
    this.budgetId && this.restService.read<ServerResponseWithBody<IBudgetCode[]>>(
      getUrlPathFragment('budget', 'tree', this.budgetId)
    ).pipe()
      .subscribe((res) => {
        this.budgetList = [{
          id: null, displayName: '--NA--', code: '',
          description: '',
          total: 0, budgetStructureMasterId: 0
        }, ...res.body];
        this.processBudgetDetails(this.itemDetails, this.budgetList);
      })

  }
  processBudgetDetails(itemDetails: IPRItemDetails[], budgetList: IBudgetCode[]) {
    if (itemDetails.length > 0) {
      const result: any = [];
      // Find unique budgetIds

      const uniqueBudgetIds = [...new Set(itemDetails
        .filter(item => item.budgetId) // Filter out items without budgetId
        .map(item => item.budgetId))];
      // Process data for each unique budgetId
      uniqueBudgetIds.forEach(budgetId => {
        // Filter itemDetails with the same budgetId
        const filteredItems = itemDetails.filter(item => item.budgetId === budgetId);

        // Calculate the sum of amountWithoutGst for the same budgetId
        const totalRequestedAmount = filteredItems.reduce((sum, item) => sum + item.amountWithoutGst, 0);

        // Get available balance from budgetDetails
        const budgetDetail = budgetList.find(budget => budget.id === budgetId);
        const availableBalance = budgetDetail ? budgetDetail.total : 0;

        result.push({ value: budgetId, availableBalance: availableBalance, requestedAmount: totalRequestedAmount, code: budgetDetail?.displayName });
      });
      this.budgetDetailsList = result;
      this.budget.emit(this.budgetDetailsList)
    }
    this.budget.emit(this.budgetDetailsList)
  }
  calculateTotalWoGst(): any {
    let totalAmountWoGst = 0;
    this.itemData.value.forEach(item => {
      totalAmountWoGst += item.amountWithoutGst;
    });
    return totalAmountWoGst;
  }
  calculateData() {
    this.itemData.subscribe(items => {
      items.forEach(item => {
        // Calculate amountWithoutGst
        item.amountWithoutGst = item.quantity * item.unitRate;

        // Calculate totalGstAmount
        item.totalGstAmount = item.amountWithoutGst * (item.gstRate / 100);

        // Calculate total
        item.total = item.amountWithoutGst + item.totalGstAmount;
      });
    });
  }
  getNextPage(event: any): void {
    this.filterParams.page = event?.first / event?.rows + 1;
    this.filterParams.pageSize = event?.rows;
    this.filterParams.sortByColumnName = event?.sortField === undefined ? null : event?.sortField;
    this.filterParams.sortOrder = event?.sortOrder;
  }
  getHeaderArray() {
    this.itemHeader = Object.keys(this.itemTableHeaderConfig).map(key => ({
      key,
      ...this.itemTableHeaderConfig[key as keyof ItemDetailsHeader]
    })) as { key: keyof ItemDetailsHeader; name: string; show: boolean }[];
  }
  deleteFieldChange(event: boolean, id: number, rowData: IPRItemDetails, rowIndex: number) {
    // Check if the id already exists in the array
    const index = this.eventsArray.findIndex(item => item.id === id);

    if (index !== -1) {
      // If the id exists, update the event
      this.eventsArray[index].event = event;
      this.convertArray[index].event = event
    } else {
      // If the id doesn't exist, add a new object to the array
      this.eventsArray.push({ id: id, event: event, rowIndex: rowIndex });
      this.convertArray.push({ event: event, rowData });
    }
    this.deleteItems.emit({ deleteItemsList: this.eventsArray, PRDetailId: this.PRDetailId })
    this.convertPOItems.emit({ convertArray: this.convertArray })
    this.previousRowData = this.itemDetails[rowIndex];
    if (event) {

      this.rowIndex.emit({ data: this.previousRowData, trigger: true })
    }
    else {
      this.rowIndex.emit({ data: this.previousRowData, trigger: false })
    }
  }
  nullItemDelete(event: boolean, id: string, rowData: IPRItemDetails, rowIndex: number) {

    // Check if the id already exists in the array
    const index = this.nullEventsArray.findIndex(item => item.id === id);

    if (index !== -1) {
      // If the id exists, update the event
      this.nullEventsArray[index].event = event;
      this.convertArray[index].event = event
    } else {
      // If the id doesn't exist, add a new object to the array
      this.nullEventsArray.push({ id: id, event: event, rowIndex: rowIndex });

      this.convertArray.push({ event: event, rowData });
    }
    this.nullDeleteItems.emit({ deleteItemsList: this.nullEventsArray, PRDetailId: this.PRDetailId })
    this.convertPOItems.emit({ convertArray: this.convertArray });
    this.previousRowData = this.itemDetails[rowIndex];
    this.rowIndex.emit(this.previousRowData)
  }
  calculateTotalGstAmount(): number {
    let totalGstAmount = 0;
    this.itemData.value.forEach(item => {
      totalGstAmount += item.totalGstAmount;
    });
    return totalGstAmount;
  }

  calculateTotal(): number {
    let total = 0;
    this.itemData.value.forEach(item => {
      total += item.total;
    });
    this.totalPRAmount.emit(total)
    return total;
  }
  editItemDetails(event: any, rowData: IPRItemDetails): void {
    if (!this.isDisable) {
      event.stopPropagation();
      const initialState: ModalOptions = {
        class: 'tg-modal-lg',
        backdrop: true,
        ignoreBackdropClick: true,
        initialState: {
          title: `Add New Item`,
          data: rowData,
          isEdit: true
        },
      };
      this.lineItemViewRef = this.modalService.show(
        PurchaseRequisitionLineItemViewComponent,
        initialState
      )
      this.lineItemViewRef.content.prLineItemData.subscribe((lineItemData: IPRItemDetails) => {
        this.editedLineItemData.emit(lineItemData)
      });
    }
  }
  onBudgetCodeChange(selection: any, itemDataRow: IPRItemDetails, rowIndex: number) {
    this.previousRowData = this.itemDetails[rowIndex];
    let code = this.budgetList.find((e: IBudgetCode) => e.id == selection.value)?.displayName;
    this.budgetDetailsList.filter((item, index) => {
      if (item.value == this.previousRowData.budgetId) {
        item.requestedAmount = item.requestedAmount - (itemDataRow.quantity * itemDataRow.unitRate);
        if (item.requestedAmount <= 0)
          this.budgetDetailsList.splice(index, 1)
      }
    })
    itemDataRow.budgetId = selection.value;
    this.budgetList.filter((budget) => {
      if (budget.id == selection.value) {
        const row = {
          value: selection.value,
          availableBalance: budget.total,
          requestedAmount: itemDataRow.quantity * itemDataRow.unitRate,
          code
        }
        if (selection.value !== null)
          this.budgetDetailsList.push(row);
        this.budget.emit(this.budgetDetailsList)
      }
    })
    this.rowData.emit(itemDataRow)
  }
  changeGSTDetails(prRowData: IPRItemDetails) {
    this.gstDetails = prRowData;
  }
}
