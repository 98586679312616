import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'tg-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnInit {
  items: MenuItem[] = [];

  adminMenuItems: MenuItem[] = [
    {
      label: 'Policy',
      icon: 'pi pi-cog',
      routerLink: '/admin/expense-metadata',
    },
    {
      label: 'Approver',
      icon: 'pi pi-users',
      routerLink: '/admin/approver',
    },
    {
      label: 'Location',
      icon: 'pi pi-map-marker',
      routerLink: '/admin/location-master',
    },
    {
      label: 'Delegations',
      icon: 'pi pi-reply',
      routerLink: '/delegation/admin',
    },
    {
      label: 'Report Queue',
      icon: 'pi pi-list',
      routerLink: '/queue/admin',
    },
    {
      label: 'CEM Master Data',
      icon: 'pi pi-th-large',
      items: [
        {
          label: 'View All Masters',
          icon: 'pi pi-cog',
          items: [
            {
              label: 'Branch Master',
              routerLink: '/cem/branch',
            },
            {
              label: 'Cost Center Master',
              routerLink: '/cem/cost-center',
            },
            {
              label: 'Department Master',
              routerLink: '/cem/department',
            },
            {
              label: 'Employee Master',
              routerLink: '/cem/employee-master',
            },
            {
              label: 'Employee Grade Master',
              routerLink: '/cem/employee-grade',
            },
            {
              label: 'Employee Type Master',
              routerLink: '/cem/employee-type',
            },
            {
              label: 'Supplier Master',
              routerLink: '/master/supplier-master-queue'
            },
            {
              label: 'Item Master',
              routerLink: '/item'
            },
            {
              label: 'GL Master',
              routerLink: '/master/gl-master-queue'
            }
          ],
        },
        {
          label: 'View MasterData Mappings',
          icon: 'pi pi-users',
          routerLink: '/master/master-mapping-queue'
        },
      ],
    },
  ];

  budgetMenuItems: MenuItem[] = [
    {
      label: 'View All Budgets',
      icon: 'bi bi-bag-plus',
      routerLink: '/budget',
    },
    {
      label: 'Create New Budget',
      icon: 'bi bi-wallet2',
      routerLink: '/budget/create',
    }
  ];

  username = '';

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.getAuth().subscribe((v) => {
      this.items = [
        {
          label: 'Profile',
          icon: 'pi pi-user',
          command: () => {
            this.router.navigate(['/profile']);
          },
        },
        {
          label: 'Entitlement',
          icon: 'pi pi-briefcase',
          command: () => {
            this.router.navigate(['/entitlement']);
          },
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => {
            this.authService.logout();
          },
        },
      ];

      let parts = v?.sub.split('@') ?? [];
      this.username = parts[0]?.replace('.', ' ') ?? '';
    });
  }
}
